body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  min-height: 100vh;
  color: #2b2233;
}

.landing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  width: 100%;
  padding-top: 24px;
}

/* Loading indicator styles */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 18px;
  color: #584d66;
}
