.hero-section {
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hero-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 48px;
  font-weight: 600;
  color: #2b2233;
  margin: 0 0 32px;
}

.hero-subtitle {
  font-size: 24px;
  color: #584d66;
  margin: 16px 0 0;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 36px;
  }

  .hero-subtitle {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 28px;
  }

  .hero-subtitle {
    font-size: 18px;
  }
}
