.app-header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header-content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.header-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.header-title a {
  color: #584d66;
  text-decoration: none;
}

.header-title-container span {
  color: #584d66;
  font-size: 16px;
}

.header-countdown {
  display: flex;
  align-items: center;
}

.header-auth {
  display: flex;
  align-items: center;
  gap: 12px;
}

.auth-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.user-email {
  display: none;
}

.user-email p {
  margin: 0;
  font-size: 14px;
  color: #584d66;
  line-height: 1.4;
}

.google-icon {
  margin-right: 8px;
}

@media (min-width: 768px) {
  .user-email {
    display: block;
  }
}

@media (max-width: 767px) {
  .header-content {
    padding: 12px 16px;
  }

  .header-title {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .header-title-container span,
  .header-countdown {
    display: none;
  }
}
